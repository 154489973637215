import React, { FC, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'
import styles from './styles'

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const swipeConfidenceThreshold = 10000

const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}

type CarouselProps = {
  images: string[]
  height: number
  initialPage?: number
}

export const Carousel: FC<CarouselProps> = ({
  images,
  height,
  initialPage = 0,
}) => {
  const [[page, direction], setPage] = useState([initialPage, 0])

  const paginate = (direction: number) => {
    setPage([page + direction, direction])
  }

  const index = wrap(0, images.length, page)

  return (
    <div css={styles.carouselContainer(height)}>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            background: `url(${images[index]})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x)

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1)
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1)
            }
          }}
        />
      </AnimatePresence>
      <div css={[styles.buttonStyles, styles.next]} onClick={() => paginate(1)}>
        {'‣'}
      </div>
      <div
        css={[styles.buttonStyles, styles.back]}
        onClick={() => paginate(-1)}
      >
        {'‣'}
      </div>
    </div>
  )
}
