import { css } from '@emotion/core'

export default {
  carouselContainer: (height: number) => css`
    width: 100%;
    height: ${height}px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  `,
  buttonStyles: css`
    top: calc(50% - 20px);
    position: absolute;
    background: white;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    z-index: 100;
  `,
  next: css`
    right: 8px;
  `,
  back: css`
    left: 8px;
    transform: scale(-1);
  `,
}
